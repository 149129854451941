body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

body > iframe {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

/* TODO: Delete these and improve styles */
h1 {
  font-size: 2em !important;
  font-weight: 600 !important;
}
h2 {
  font-size: 1.8em !important;
  font-weight: 600 !important;
}
h3 {
  font-size: 1.6em !important;
  font-weight: 600 !important;
}
h4 {
  font-size: 1.4em !important;
  font-weight: 600 !important;
}
h5 {
  font-size: 1.2em !important;
  font-weight: 600 !important;
}
h6 {
  font-size: 1em !important;
  font-weight: 600 !important;
}

svg text.quadrant-text,
svg text.horizon-text,
.horizon-text,
.quadrant-text {
  fill: rgba(0, 0, 0, 1) !important;
}
svg text.quadrant-text:hover {
  fill: rgba(0, 0, 0, 0.7) !important;
}
/* TODO End delete these */

/* SCROLLBAR */
*::-webkit-scrollbar {
  width: 6px;
}
*::-webkit-scrollbar-track {
  background-color: none;
  border-radius: 100px;
}
*::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 100px;
}
* {
  scrollbar-color: none grey;
  scrollbar-width: 6px;
  scrollbar-gutter: stable;
}
/* END SCROLLBAR */

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* React leaflet */
.leaflet-container {
  width: 100%;
  height: 100vh;
}
