header {
  color: rgb(27, 77, 124);
  margin-bottom: 7px;
}

div.rc-slider {
  margin-left: -5px;
  width: 220px;
}

.option-button {
  align-items: center;
  display: flex;
  justify-content: space-between;
  z-index: 1;

  + div {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &--mapPage {
    @media only screen and (max-width: 400px) {
      justify-content: flex-start !important;
    }
  }

  @media only screen and (max-width: 1400px) {
    h2 {
      order: 2;
    }

    button:last-of-type {
      order: 3;
    }
  }

  button {
    margin-left: 0;
  }

  @media only screen and (max-width: 768px) {
    justify-content: flex-end;

    .titleHeader,
    .howTo {
      display: none;
    }

    .quadrantFilter,
    .mapFilter {
      margin-top: 160px;
      zoom: 0.6;
    }

    .mapFilter {
      position: fixed;
      top: -20px;
    }
  }
}

@media only screen and (max-width: 921px) {
  .option-button {
    width: 100%;
  }
  .titleHeader {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .option-button {
    left: 0;
    margin: 0px;
    width: 100%;
  }
}

.filterWrapper {
  padding-right: 10px;
}
