.blipListQuadrant {
  cursor: pointer;
  display: flex;
  padding: 5px;

  h4 {
    flex: 1;
    text-align: left;
  }
}

.blipList {
  width: 400px;

  &-scroll {
    display: none;
    overflow: hidden;
    scroll-behavior: smooth;

    @media only screen and (min-width: 1900px) {
      max-height: 100% !important;
    }

    &--overflowX {
      overflow-x: auto;
    }

    &--overflowY {
      overflow-y: auto;
    }

    &--show {
      display: block;
    }
  }

  &-title--center {
    text-align: center;
  }
}
