.techDescription {
  &-title {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
  }

  &-text {
    text-align: left;
  }
}
