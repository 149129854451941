// make font larger, not used for animation
.loadingEllipsis {
  // the animation is created by widening :after with ellipsis
  &:after {
    // ensure animation
    overflow: hidden;
    display: inline-block;

    // align periods
    vertical-align: bottom;

    // set up initial condition for the animation
    width: 0px;

    // ascii code for ellipsis
    content: '\2026';

    // run the animation, edit by changing steps and width
    animation: ellipsis steps(4, end) 900ms infinite;
  }
}

// edit the animation by changing steps and width
@keyframes ellipsis {
  to {
    width: 1em;
  }
}
