#radar-container.radarContainer {
  align-items: center;
  display: flex;
  gap: 40px;
  height: max-content;
  justify-content: space-between;
  margin: 30px 28px 30px 0;
  width: 100%;

  @media only screen and (max-height: 725px) {
    align-items: center !important;
    margin: 0 auto;
    max-width: 1200px;
  }

  @media only screen and (max-height: 620px) {
    max-width: 1000px;
  }

  @media only screen and (min-width: 1401px) {
    align-items: flex-start;
    height: 80vh;
    .tabsComponents {
      height: 100%;
      margin: 0 !important;
      overflow-y: auto;
    }
  }
  .tabsComponents {
    border: none;
    display: flex;
    flex-basis: 50%;
    margin: 0 0 15px !important;
    max-width: none !important;
    padding: 20px 5px;

    * {
      border: none;
      box-shadow: none;
    }

    .chakra-tabs {
      width: 100%;
      h5 {
        cursor: pointer;
      }
    }
    .chakra-tabs__tab-panel {
      padding: 16px 0;
    }
    .MuiAccordionDetails-root {
      padding: 8px 0;
    }
    td {
      padding: 16px 5px;
    }
    @media only screen and (max-width: 1400px) {
      min-width: 90vw;

      .chakra-tabs {
        width: 80%;
      }
    }

    @media only screen and (max-width: 991px) {
      justify-content: center;
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }

    @media only screen and (max-width: 825px) {
      p {
        width: auto;
      }

      h5 {
        font-size: 15px !important;
        font-weight: thin !important;
      }
    }

    @media only screen and (min-width: 768px) {
      justify-content: center;
      overflow-x: auto;

      p {
        width: auto;
      }

      h5 {
        font-size: 15px !important;
        font-weight: thin !important;
      }
    }

    @media only screen and (min-width: 600px) {
      justify-content: center;
      overflow-x: auto;

      p {
        font-weight: 600;
        width: auto;
      }

      h5 {
        font-size: 22px !important;
        font-weight: 500 !important;
      }

      h6 {
        font-size: 15px !important;
        font-weight: 500 !important;
      }
    }
  }

  .radarComponents {
    @media only screen and (min-height: 920px) {
      zoom: 1.3;
    }

    @media only screen and (max-height: 725px) {
      zoom: 0.8;
    }

    @media only screen and (max-height: 620px) {
      zoom: 0.6;
    }
    div {
      svg {
        right: -9px !important;
        @media only screen and (max-width: 991px) {
          top: 292px !important;
        }
      }
    }
    @media only screen and (max-width: 991px) {
      display: flex;
      justify-content: center;
      z-index: 1;
      zoom: 1;
    }
    @media only screen and (max-width: 767px) {
      margin-top: 80px;
      width: 100vw;
    }
    @media only screen and (max-width: 610px) {
      transform: scale(0.7);
      div:first-of-type {
        display: flex;
        justify-content: center;
        overflow: visible;
      }
    }
    @media only screen and (max-width: 425px) {
      transform: scale(0.5);
    }
  }

  @media only screen and (max-width: 1400px) {
    align-items: center;
    flex-direction: column;
    gap: 10px !important;
    margin: 0;
  }

  @media only screen and (max-width: 991px) {
    margin: 0 auto;
  }

  @media only screen and (max-width: 767px) {
    margin-top: -5vh !important;
    + div {
      display: none !important;
    }

    path,
    .blip {
      display: none;
    }

    text {
      &.horizon-text {
        display: none;
      }
    }

    g.quadrant-text {
      scale: 1.9;

      &.quadrant-preparedness {
        fill: green !important;
        position: relative;
        translate: 5vw 22vh;
      }

      &.quadrant-response {
        fill: red !important;
        translate: -10vw 22vh;
      }

      &.quadrant-mitigation {
        fill: blue !important;
        translate: 12vw -18vh;
      }

      &.quadrant-recovery {
        fill: #e7d438 !important;
        translate: -10vw -18vh;
      }
    }

    .maturity-levels-desc {
      display: none;
    }
  }
}

.horizontalList {
  flex: 0.75;
  td {
    padding: 10px 0;
  }
}

@media only screen and (min-width: 992px) {
  .how-to-button {
    right: 150px !important;
  }
}

@media only screen and (max-width: 991px) {
  .how-to-button {
    left: 55px;
    width: fit-content !important;
  }
}

@media only screen and (max-width: 767px) {
  .quadrant-filter {
    align-self: flex-end;
    display: flex !important;
    left: unset;
    position: absolute;
    top: 40px;
    zoom: 0.6;

    @media only screen and (max-width: 392px) {
      top: 60px;
      z-index: 12;
    }
  }

  .radarComponentsContainer {
    > div:last-of-type {
      display: none !important;
    }
  }
}

.quadrants {
  > g:nth-of-type(1) {
    transform: translate(272px, -266px);
  }
  > g:nth-of-type(2) {
    transform: translate(272px, 266px);
  }
  > g:nth-of-type(3) {
    transform: translate(-272px, 266px);
  }
  > g:nth-of-type(4) {
    transform: translate(-272px, -266px);
  }
}
