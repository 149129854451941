.popOverTitle {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
}

.popOverDescription {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  margin: 20px 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popBadge {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  max-width: 17ch; // MAX 300PX / 2
  overflow: hidden;
  text-overflow: ellipsis;
}
