.techItem {
  border-radius: 20px;
  height: 20px;
  width: 25px;
}

.scrollableDiv {
  display: flex;
  flex-wrap: wrap;
  max-height: 80px;
  overflow: hidden;
  scroll-behavior: smooth;

  &-overflowX {
    overflow-x: auto;
  }

  &-overflowY {
    overflow-y: auto;
  }
}

button {
  &.button {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex: auto;
    margin-bottom: 6px;
    margin-right: 12px;
    padding: 1px;
  }

  .btnContainer {
    width: 100%;
  }

  .btnTechItem {
    border: 1px solid lightgrey;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    padding: 4px;
  }
}
