.popoverContent {
  margin-left: 10px;
  margin-right: 20px;
  min-height: 400px;
  min-width: 400px;
  overflow-y: hidden;
}

.timeline-container {
  margin: 10px auto;
  padding: 20px;
}

.timeline {
  border-left: 2px solid rgb(46, 133, 133);
  max-height: 650px;
}

.container {
  color: gray;
  margin: 0 0 20px 20px;
  max-width: 500px;
  padding: 10px;
  position: relative;

  a {
    color: blue;
    text-decoration: underline;
  }
}

header h1 {
  font-size: 17px !important;
}

.container .content h2 {
  color: #000000;
  font-size: 18px !important;
  font-weight: 500;
  margin-left: 20px;
  margin-top: -20px;
}

.container .content h2::before {
  background: white;
  border: 3px solid rgb(46, 133, 133);
  border-radius: 100px;
  content: '';
  height: 20px;
  left: -31px;
  position: absolute;
  width: 20px;
}

.container .content .timeline-boundary::before {
  background: rgb(46, 133, 133);
  border: 3px solid rgb(46, 133, 133);
}

.container .content p {
  color: #000000;
  font-size: 15px;
  margin: 10px 0 0px 20px;
}

.container .content img {
  height: auto;
  width: 100px;
}
